<template>
  <div>
    <CRow>
      <CCol col="12" lg="6">
        <change-password />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import changePassword from "./ChangePassword.vue";
export default {
  components: {
    changePassword,
  },
};
</script>
