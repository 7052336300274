<template>
  <CCard>
    <CCardHeader>
      <span class="font-weight-bold">Thay đổi mật khẩu</span>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <CInput
            label="Mật khẩu hiện tại"
            placeholder="Mật khẩu hiện tại"
            v-model="oldPassword"
            type="password"
            horizontal
            :add-input-classes="{
              'is-invalid': !!inValidObject['oldPassword'],
            }"
            :invalid-feedback="inValidObject['oldPassword']"
            @keyup.enter="savePassword"
          />
        </CCol>
        <CCol md="12">
          <CInput
            label="Mật khẩu mới"
            placeholder="Mật khẩu mới"
            v-model="newPassword"
            type="password"
            horizontal
            :add-input-classes="{
              'is-invalid': !!inValidObject['newPassword'],
            }"
            :invalid-feedback="inValidObject['newPassword']"
            @keyup.enter="savePassword"
          />
        </CCol>
        <CCol md="12">
          <CInput
            label="Xác nhận mật khẩu"
            placeholder="Xác nhận mật khẩu"
            v-model="confirmPassword"
            type="password"
            horizontal
            :add-input-classes="{
              'is-invalid': !!inValidObject['confirmPassword'],
            }"
            :invalid-feedback="inValidObject['confirmPassword']"
            @keyup.enter="savePassword"
          />
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <CButton color="primary" :disabled="saving" @click="savePassword">
        <CIcon name="cil-save" />
        Cập nhật mật khẩu
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      saving: false,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      inValidObject: {},
    };
  },
  methods: {
    async savePassword() {
      this.inValidObject = this.checkValid(
        this.oldPassword,
        this.newPassword,
        this.confirmPassword
      );

      if (Object.keys(this.inValidObject).length) {
        return;
      }

      this.saving = true;
      try {
        let resp = await this.$http.post(`Settings/ChangePassword`, {
          OldPassword: this.oldPassword,
          NewPassword: this.newPassword,
        });
        if (resp && resp.status == 200) {
          if (resp.data.message) {
            alert(this.$const.MESSAGE[resp.data.message]);
          }
          if (resp.data.status == this.$const.RESPONSE_TYPES.Success) {
            this.oldPassword = null;
            this.newPassword = null;
            this.confirmPassword = null;
            this.$user.dispatch("logout").then(() => {
              let returnUrl = this.$router.currentRoute.fullPath;
              let query = returnUrl ? { returnUrl } : null;
              this.$router.push({ name: "Login", query });
            });
          }
        } else {
          alert(this.$const.MESSAGE.UpdateError);
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(oldPassword, newPassword, confirmPassword) {
      let inValidObject = {};

      if (!oldPassword) {
        inValidObject["oldPassword"] = "Vui lòng nhập Mật khẩu hiện tại";
      }

      if (!newPassword) {
        inValidObject["newPassword"] = "Vui lòng nhập Mật khẩu mới";
      }

      if (!confirmPassword) {
        inValidObject["confirmPassword"] = "Vui lòng nhập Xác nhận mật khẩu";
      } else if (confirmPassword != newPassword) {
        inValidObject["confirmPassword"] = "Xác nhận mật khẩu không đúng";
      }

      return inValidObject;
    },
  },
};
</script>
